@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@keyframes scale-in-check {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

.productPage {
    overflow: hidden;
    flex-direction: column;

    &.theme--makers-reserve {
        background-color: #191815;
        color: var(--colors-cream);
    }
}

.accordion {
    @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
}

.header {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: var(--header-padding, 6.5rem 0 4.5rem 0);
    background-color: var(--product-background-color);
    color: var(--colors-cream);

    > div {
        max-width: var(--block-max-width-plus-padding);
        margin: 0 auto;
    }

    &--dark-text {
        color: var(--colors-blue);
    }

    .theme--makers-reserve & {
        color: var(--colors-makersReserveBlack);
    }
}

.headerInner {
    position: relative;
}

.iconPlacement {
    position: absolute;
    z-index: 10;

    // factor in header padding
    top: -1.56rem;
    right: var(--icon-right-position, 4rem);
}

.icon {
    position: relative;
    width: 6rem;
    color: var(--colors-cream);
}

.awardWrapper {
    margin: var(--award-margin, 0 0 1.2rem);
}

.award {
    max-width: var(--award-max-width, 16.6rem);
}

.awardAsset {
    width: var(--award-asset-width, 5rem);
    height: var(--award-asset-height, 5rem);
}

.productImage {
    --productImage-padding: 6rem 2rem 2rem;
    --productImage-maxHeight: 32rem;

    display: flex;
    width: 100%;
    height: 100%;
    max-height: var(--block-max-height);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--productImage-padding);
    grid-column: var(--productImage-grid-column, span 4);
    grid-row: var(--productImage-grid-row, 1);
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;

    &.hasAward {
        width: 80%;
    }
}

.image {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    picture {
        position: relative;
    }

    img {
        position: relative;
        z-index: 2;
        width: auto;
        max-height: var(--productImage-maxHeight);
        margin: 0 auto;
    }
}

.imageInner {
    position: relative;
}

.imageShadow {
    --highlight-height: 80%;

    position: absolute;
    z-index: 1;
    bottom: 0;
    left: calc((-1 * var(--productImage-padding)) + -25%);
    display: block;
    width: calc(
        150% + var(--productImage-padding) + var(--productImage-padding)
    );
    padding-top: var(--highlight-height);
    background-blend-mode: overlay, soft-light;
    background-image: radial-gradient(
        56% 33% at 50.14% 60.12%,
        var(--colors-white) 0%,
        rgba(255, 255, 255, 50%) 40%,
        rgba(255, 255, 255, 0%) 90%
    );
    background-size: auto;
    mix-blend-mode: soft-light;
    transform: translateY(30%);
}

.titleContainer {
    display: flex;
    width: var(--titleContainer-width, 100%);
    flex-direction: column;
    align-items: var(--titleContainer-align-items, center);
    justify-content: center;
    margin-top: var(--titleContainer-margin-top, 4.5rem);
    grid-column: var(--titleContainer-grid-column, span 4);
    grid-row: var(--titleContainer-grid-row, 2);
    text-align: var(--titleContainer-text-align, center);
    text-transform: uppercase;

    .theme--makers-reserve & {
        color: var(--colors-black);
        text-transform: none;
    }

    &.hasAward {
        --titleContainer-margin-top: 3.6rem;
    }
}

.eyebrow {
    margin-bottom: var(--eyebrow-margin, 1.2rem);
}

.title {
    width: 100%;
    margin-bottom: 3.6rem;
    animation-fill-mode: forwards;
}

.cta {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    animation-fill-mode: forwards;
}

.discontinued {
    @include flex-center;

    width: 100%;
    flex-direction: row;
    gap: 1.1rem;
    text-transform: none;

    svg {
        width: 2.2rem;
    }
}

.ctaButtons {
    display: flex;
    margin-bottom: 2.4rem;

    > *:first-child {
        margin-right: 1rem;
    }
}

.weights {
    width: 100%;
    animation: fade-in $slow 0.1s;
    animation-fill-mode: forwards;
}

.productVariantsContainer {
    display: flex;
    width: 100%;
    background-color: var(--colors-white);
    overflow-x: auto;
}

.productVariants {
    display: flex;
    align-items: center;
    justify-content: var(--productVariants-justify-content);
    padding: 0 4.8rem 0 2.4rem;
    margin: 0;
    list-style: none;

    > li {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        margin: 3.4rem 1.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.productVariant {
    $block: &;

    position: relative;
    display: flex;
    width: 15rem;
    height: 15rem;
    flex-direction: column;
    align-items: center;
    padding-top: 2.3rem;
    border-radius: 50%;
    color: var(--colors-blueDark);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color $slow;

    &:hover,
    &:active {
        background-color: rgba(color('cream'), 0.5);
    }

    .theme--makers-reserve & {
        color: var(--colors-makersReserveBlack);
    }

    &__title {
        width: 11rem;
    }

    &--selected {
        background-color: var(--colors-cream) !important;

        .productVariantIconChecked {
            position: absolute;
            top: 0.8rem;
            right: 0.8rem;
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            animation: scale-in-check $default $easeInOutCubic;
        }
    }

    picture {
        width: 8.8rem;
        height: 7.6rem;
        padding-bottom: 0.5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.content {
    padding: 0 var(--block-padding-side);
    margin-top: var(--block-padding-end);
}

.blocks {
    margin-bottom: var(--block-padding-end);
}

@include medium {
    .header {
        --header-padding: 15rem 0;

        background-color: var(--product-background-color);
    }

    .iconPlacement {
        top: -10rem;
    }

    .imageContainer {
        &.hasAward {
            width: 100%;
        }
    }

    .cta {
        align-items: flex-start;

        > a {
            align-self: flex-start;
        }
    }

    .discontinued {
        justify-content: flex-start;
    }

    .productPage {
        --titleContainer-grid-row: 1;
        --titleContainer-text-align: left;
        --titleContainer-align-items: flex-start;
        --titleContainer-margin-top: 0;
        --eyebrow-margin: 2.4rem;
        --award-margin: 0 0 2.4rem;
        --award-asset-height: 7rem;
        --award-asset-width: 7rem;
        --award-max-width: 24rem;
        --productImage-grid-column: 5 / span 4;
        --productImage-grid-row: 1;
        --productVariants-justify-content: center;
    }
}

@include large {
    .productPage {
        --titleContainer-grid-column: 1 / span 4;
        --productImage-grid-column: 6 / span 6;
    }

    .header {
        --header-padding: 6.5rem 0 4.5rem 0;

        min-height: 64rem;
    }

    .icon {
        width: 8rem;
    }

    .iconPlacement {
        top: -1.4rem;

        --icon-right-position: 9.6rem;
    }

    .productImage {
        // need to specify unit or calc() won't work
        --productImage-padding: 0rem;
        --productImage-maxHeight: 50rem;
    }

    .productVariantsContainer {
        justify-content: center;
    }

    .productVariants {
        padding: 0 4.8rem;
    }

    .ctaButtons {
        min-width: 36rem;
    }
}

@include xxLarge {
    .productPage {
        --titleContainer-grid-column: 2 / span 4;
    }

    .productImage {
        // need to specify unit or calc() won't work
        --productImage-padding: 0rem;
        --productImage-maxHeight: 60rem;
    }

    .header {
        min-height: 80rem;
    }

    .title {
        margin-bottom: 4.8rem;
    }
}

@include xxxLarge {
    .productImage {
        --productImage-maxHeight: 64rem;

        grid-column-end: -1;
        grid-column-start: 7;
    }

    .titleContainer {
        grid-column-start: 1;
    }

    .header {
        min-height: 100rem;
    }
}
